<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Hatchability(%)</b-card-title>

      <div class="mr-2">
        <div class="d-flex">
          <div class="d-flex align-items-center mr-1 font-weight-bold">
            Year
          </div>
          <!-- <v-select
            v-model="selected"
            label="option"
            :options="option"
            style="width: 120px"
            @input="getSelectedYear"
          ></v-select> -->
        </div>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import vmson from "@/libs/empty";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    vSelect,
    VueApexCharts,
  },
  data() {
    return {
      selected: ["2022"],
      option: [2022],
      series: [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          // title: {
          //   text: "Month",
          // },
        },
      },
    };
  },
  // created() {
  //   this.getData();
  // },
  // mounted() {
  //   vmson.$on("farmIdEvent", (id) => {
  //     this.getData(id);
  //   });
  // },
  // methods: {
  //   getSelectedYear(event) {
  //     this.selected = event;
  //     this.getData();
  //   },
  //   getData(id) {
  //     let farmId = id;
  //     let url = "";
  //     if (farmId === undefined) {
  //       farmId = this.$route.params.farmId;
  //     } else {
  //       farmId = id;
  //     }
  //     if (farmId) {
  //       url = `web/dashboard-hatchability?year=${this.selected}&farmId=${farmId}`;
  //     } else {
  //       url = `web/dashboard-hatchability?year=${this.selected}`;
  //     }
  //     //   var farmId = this.$route.params.farmId;
  //     axiosIns.get(url).then((response) => {
  //       let data = response.data.map((item) => {
  //         return item.mortality_percentage.toFixed();
  //       });

  //       this.series = [
  //         {
  //           data: data,
  //         },
  //       ];
  //     });
  //   },
  // },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
